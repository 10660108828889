* {
  min-height: 0;
  min-width: 0;
  flex-shrink: 0;
}

.animate-rotate {
  animation: rotate 0.6s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple.blue div {
  border: 4px solid #2a81eb;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.axll-app .swiper-pagination {
  bottom: 0 !important;
  height: 48px !important;
  @apply flex pointer-events-none justify-center items-center bg-gradient-to-t from-black/25;
}

.axll-app .swiper-pagination .swiper-pagination-bullet {
  @apply bg-white opacity-50 !important;
}

.axll-app
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-white opacity-100 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  appearance: none;
  -moz-appearance: textfield; /* Firefox */
}

@keyframes notification {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.notification {
  animation-name: notification;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.recharts-cartesian-axis-tick:first-child {
  display: none !important;
}

.inventory-notification {
  @apply font-medium;
}

.inventory-notification li,
.inventory-notification p {
  @apply leading-normal;
}

.inventory-notification a {
  @apply text-axll-blue hover:underline;
}
