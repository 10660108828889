.react-datepicker-popper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.react-datepicker__header {
  @apply bg-white border-none p-0;
}

.react-datepicker {
  @apply top-0 drop-shadow-md bg-white rounded-xl border-none;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day-name {
  @apply uppercase text-xs font-medium tracking-wide text-axll-dark-grey;
}

.react-datepicker__day {
  @apply text-base font-medium tracking-tight;
  height: 28px;
  width: 28px;
}

.react-datepicker__day,
.react-datepicker__day:hover {
  @apply rounded-full;
}

.react-datepicker__day:hover {
  @apply bg-axll-silver;
}

.react-datepicker__day.react-datepicker__day--selected {
  @apply bg-axll-blue bg-opacity-20 text-axll-blue;
}

.react-datepicker__day--keyboard-selected {
  @apply bg-transparent text-black;
}

.react-datepicker__day--disabled:hover {
  @apply bg-transparent;
}

.react-datepicker__month-text {
  @apply uppercase font-semibold tracking-tight p-2 rounded-full transition;
}

.react-datepicker__month.react-datepicker__monthPicker {
  @apply relative z-10 overflow-visible -mt-2;
}

.react-datepicker__month-text:hover {
  @apply bg-axll-silver rounded-full;
}

.react-datepicker__month-text.react-datepicker__month--selected,
.react-datepicker__month-text.react-datepicker__month--selected:hover {
  @apply bg-axll-blue bg-opacity-20 text-axll-blue;
}

.react-datepicker__month-text--keyboard-selected {
  @apply bg-transparent text-black;
}
